<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Note</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="note-form">
          <v-textarea
            label="Note *"
            v-model="fields.note_text"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('note_text')"
            :error-messages="errors['note_text']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="note-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        note_text: null,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  methods: {
    openForm: function (dog = null) {
      if (dog !== null) {
        this.dog = dog;
        this.fields.note_text = dog.note_text;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.dogId = this.dog.id;

      this.$store
        .dispatch("lhl/dogs/saveDogNote", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields = {
        note: null,
      };
    },
  },
};
</script>
