<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Dog</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="basic-form">
          <v-text-field
            label="Name"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>

          <v-text-field
            label="Dob"
            v-model="fields.dob"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('dob')"
            :error-messages="errors['dob']"
            @change="dobHasChanged()"
          ></v-text-field>

          <v-text-field
            label="Retiral Date"
            v-model="fields.retiral_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('retiral_date')"
            :error-messages="errors['retiral_date']"
          ></v-text-field>

          <v-textarea
            label="Retiral Comments"
            v-model="fields.retiral_comments"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('retiral_comments')"
            :error-messages="errors['retiral_comments']"
          ></v-textarea>

          <v-text-field
            label="Grade"
            v-model="fields.grade"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('grade')"
            :error-messages="errors['grade']"
          ></v-text-field>

          <v-text-field
            label="Microchip"
            v-model="fields.microchip"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('microchip')"
            :error-messages="errors['microchip']"
          ></v-text-field>

          <v-text-field
            label="Identifier"
            v-model="fields.identifier"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('identifier')"
            :error-messages="errors['identifier']"
          ></v-text-field>

          <v-text-field
            label="Kennel Name"
            v-model="fields.kennel_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('kennel_name')"
            :error-messages="errors['kennel_name']"
          ></v-text-field>

          <v-text-field
            label="Colour dna"
            v-model="fields.colour_dna"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('colour_dna')"
            :error-messages="errors['colour_dna']"
          ></v-text-field>

          <v-select
            label="Size"
            v-model="fields.size_id"
            :items="sizes"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('size_id')"
            :error-messages="errors['size_id']"
          ></v-select>

          <v-select
            label="Colour"
            v-model="fields.colour_id"
            :items="colours"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('colour_id')"
            :error-messages="errors['colour_id']"
          ></v-select>

          <v-select
            label="Markings"
            v-model="fields.marking_id"
            :items="markings"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('marking_id')"
            :error-messages="errors['marking_id']"
          ></v-select>

          <v-select
            label="Gender"
            v-model="fields.gender"
            :items="genders"
            item-text="name"
            item-value="value"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('gender')"
            :error-messages="errors['gender']"
          ></v-select>

          <v-select
            label="Coat Type"
            v-model="fields.coat_type_id"
            :items="coat_types"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('coat_type_id')"
            :error-messages="errors['coat_type_id']"
          ></v-select>

          <v-select
            label="Breed"
            v-model="fields.breed_id"
            :items="breeds"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('breed_id')"
            :error-messages="errors['breed_id']"
          ></v-select>

          <v-select
            label="Status"
            v-model="fields.status"
            :items="statuses"
            item-text="name"
            item-value="value"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('status')"
            :error-messages="errors['status']"
          ></v-select>

          <v-select
            label="Location"
            v-model="fields.location"
            :items="locations"
            item-text="name"
            item-value="value"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('location')"
            :error-messages="errors['location']"
          ></v-select>

          <v-text-field
            v-if="dog.status == 'puppy'"
            label="Time of Birth"
            v-model="fields.time_of_birth"
            type="time"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('time_of_birth')"
            :error-messages="errors['time_of_birth']"
          ></v-text-field>

          <v-select
            v-if="dog.status == 'puppy'"
            label="Temperament"
            v-model="fields.temperament"
            :items="temperaments"
            item-text="name"
            item-value="value"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('temperament')"
            :error-messages="errors['temperament']"
          ></v-select>

          <v-text-field
            v-if="dog.status == 'puppy'"
            label="Puppy Collection Date"
            v-model="fields.puppy_collection_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('puppy_collection_date')"
            :error-messages="errors['puppy_collection_date']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="basic-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dog: {
      required: true,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
  },

  data() {
    return {
      fields: {
        kennel_name: null,
        identifier: null,
        gender: null,
        grade: null,
        location: null,
        status: null,
        dob: null,
        breed_id: null,
        size_id: null,
        dob_has_changed: false,
        marking_id: null,
        coat_type_id: null,
        colour_id: null,
        microchip: null,
        puppy_collection_date: null,
        temperament: null,
        time_of_birth: null,
        retiral_date: null,
        retiral_comments: null,
        formName: null,
      },
      statuses: [
        { name: "Breeding Dog", value: "breeding-dog" },
        { name: "Speyed/Neutered", value: "speyed" },
        { name: "Breeding Complete", value: "breeding-complete" },
        { name: "Puppy", value: "puppy" },
        { name: "External", value: "external" },
      ],

      locations: [
        { name: "LHL", value: "lhl" },
        { name: "FCH", value: "fch" },
        { name: "Breeder Colleague", value: "breeder-colleague" },
        { name: "Customer", value: "customer" },
        { name: "External", value: "external" },
      ],
      genders: [
        { name: "Female", value: "F" },
        { name: "Male", value: "M" },
      ],
      temperaments: [
        { name: "Neutral", value: "Neutral" },
        { name: "Passive", value: "Passive" },
        { name: "Active", value: "Active" },
      ],

      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {
    breeds() {
      return this.$store.state.lhl.breeds["breeds"];
    },

    coat_types() {
      let types = this.$store.state.lhl.dogs["coat_types"];

      types = types.filter((a) => {
        return a.breed_id == this.dog.breed_id;
      });

      return types;
    },

    sizes() {
      let sizes = this.$store.state.lhl.dogs["sizes"];

      sizes = sizes.filter((a) => {
        return a.breed_id == this.dog.breed_id;
      });

      return sizes;
    },

    colours() {
      let colors = this.$store.state.lhl.dogs["colors"];

      colors = colors.filter((a) => {
        return a.breed_id == this.dog.breed_id;
      });

      return colors;
    },

    markings() {
      let markings = this.$store.state.lhl.dogs["markings"];

      markings = markings.filter((a) => {
        return a.breed_id == this.dog.breed_id;
      });

      return markings;
    },
  },

  methods: {
    dobHasChanged: function () {
      this.fields.dob_has_changed = true;
    },

    openForm: function (dog = null) {
      if (dog !== null) {
        this.dog = dog;

        this.fields.name = dog.name;
        this.fields.kennel_name = dog.kennel_name;
        this.fields.identifier = dog.identifier;
        this.fields.gender = dog.gender;
        this.fields.grade = dog.grade;
        this.fields.location = dog.location;
        this.fields.status = dog.status;
        this.fields.dob = dog.dob;
        this.fields.microchip = dog.microchip;
        this.fields.colour_dna = dog.colour_dna;

        this.fields.retiral_date = dog.retiral_date;
        this.fields.retiral_comments = dog.retiral_comments;

        this.fields.breed_id = dog.breed_id ? dog.breed.id : null;
        this.fields.size_id = dog.size_id ? dog.size.id : null;
        this.fields.marking_id = dog.marking_id ? dog.marking.id : null;
        this.fields.coat_type_id = dog.coat_type_id ? dog.coat_type.id : null;
        this.fields.colour_id = dog.colour_id ? dog.colour.id : null;

        this.fields.puppy_collection_date = dog.puppy_collection_date;
        this.fields.temperament = dog.temperament;
        this.fields.time_of_birth = dog.time_of_birth;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        fields: this.fields,
      };

      payload.fields.formName = "basic";

      this.$store
        .dispatch("lhl/dogs/saveBasic", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.kennel_name = null;
      this.fields.identifier = null;
      this.fields.gender = null;
      this.fields.grade = null;
      this.fields.location = null;
      this.fields.status = null;
      this.fields.dob = null;
      this.fields.breed_id = null;
      this.fields.size_id = null;
      this.fields.marking_id = null;
      this.fields.coat_type_id = null;
      this.fields.colour_id = null;
      this.fields.microchip = null;
      this.fields.dob_has_changed = false;

      this.fields.puppy_collection_date = null;
      this.fields.temperament = null;
      this.fields.time_of_birth = null;
    },
  },
};
</script>
