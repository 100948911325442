<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit an" : "Add an" }} Origin Contact</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="weight-form">
          <v-select
            label="Contact"
            v-model="fields.customer_id"
            :items="customers"
            item-text="full_name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('customer_id')"
            :error-messages="errors['customer_id']"
          >
            <template v-slot:prepend-item>
              <div class="pa-3">
                <v-text-field
                  label="Search Customer"
                  v-model="customerSearchTerm"
                  outlined
                  autofocus
                  dense
                  hide-details
                ></v-text-field>
              </div>
              <v-divider></v-divider>
            </template>
          </v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="weight-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      customerSearchTerm: "",
      fields: {
        customer_id: null,
      },

      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {
    customers() {
      let customers = this.$store.getters["lhl/customers/all"];

      if (this.customerSearchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.full_name.toLowerCase();
          const customerSearchTerm = this.customerSearchTerm.toLowerCase();
          return name.includes(customerSearchTerm);
        });
      }

      return customers;
    },
  },

  methods: {
    openForm: function (dog = null) {
      if (dog !== null) {
        this.dog = dog;

        this.fields.customer_id = dog.customer_id;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        fields: this.fields,
      };

      payload.fields.formName = "contact";

      this.$store
        .dispatch("lhl/dogs/saveBasic", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.origin_contact = null;
    },
  },
};
</script>
