<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Profile Image</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="profile-form">
          <v-file-input
            label="Profile Image"
            v-model="fields.profile_image"
            placeholder="Choose a image"
            outlined
            :error="errors.hasOwnProperty('profile_image')"
            :error-messages="errors['profile_image']"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="profile-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        profile_image: null,
      },

      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {},

  methods: {
    openForm: function (dog = null) {
      if (dog !== null) {
        this.dog = dog;

        this.fields.profile_image = dog.profile_image
          ? new File([dog.profile.url], dog.profile.url)
          : null;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        fields: this.fields,
      };

      payload.fields.formName = "profile";

      this.$store
        .dispatch("lhl/dogs/saveBasic", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.profile_image = null;
    },
  },
};
</script>
